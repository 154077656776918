<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{ $t("message.notifications") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        :className="'w100'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            @c-create="drawerCreate = true"
                            :columns="columns"
                            @c-change="updateColumn"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.notification_type.show">
                                {{ columns.notification_type.title }}
                            </th>

									 <th v-if="columns.user.show">
                                {{ columns.user.title }}
                            </th>

									 <th v-if="columns.text.show" style="width:400px">
                                {{ columns.text.title }}
                            </th>

									<th v-if="columns.users.show">
                                {{ columns.users.title }}
                            </th>

									  <th v-if="columns.activity_time.show">
                                {{ columns.activity_time.title }}
                            </th>

                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.id"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.notification_type.show">
										<select-notification-types
										 	:placeholder="columns.notification_type.title"
											:class="mode ? 'filter__day' : 'filter__night'"
											v-model="filterForm.notification_type_id"
											:id="filterForm.notification_type_id"
										></select-notification-types>
                            </th>
									  <th v-if="columns.user.show">
											<select-users
												:placeholder="columns.user.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.user_id"
												:id="filterForm.user_id"
											></select-users>
                            </th>
									  <th v-if="columns.text.show">
                                <crm-input
                                    :placeholder="columns.text.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.text"
                                ></crm-input>
                            </th>
									   <th v-if="columns.users.show">
											   <crm-input
                                    :placeholder="columns.users.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.text"
												:disabled="true"
                                ></crm-input>
                            </th>
				
									  <th v-if="columns.activity_time.show">
										   <crm-date-picker
                                    :placeholder="columns.activity_time.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.activity_time"
                                ></crm-date-picker>
                            </th>
                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="notification in list"
                            :key="notification.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">
                                {{ notification.id }}
                            </td>

                            <td v-if="columns.notification_type.show">
                                {{ notification.type ? notification.type.name : '' }}
                            </td>
									    <td v-if="columns.user.show">
                                {{ notification.user ? notification.user.surname : '' }}
										  {{ notification.user ? notification.user.name : '' }}
                            </td>
									    <td v-if="columns.text.show">
                                {{ notification.text }}
                            </td>
									<td v-if="columns.users.show">
										 <el-button type="text" @click="showItems(notification.id)">{{ $t('message.view') }}</el-button>
                            </td>
									  <!-- <td>
                                {{ notification.notificationable_type ? $t('message.' + notification.notificationable_type) : '' }}
                            </td> -->
									   <td v-if="columns.activity_time.show">
                                {{ notification.activity_time }}
                            </td>

                            <td v-if="columns.created_at.show">
                                {{ notification.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ notification.updated_at }}
                            </td>

                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="notification"
                                    :actions="actions"
                                    :permissionShow="'countries.update'"
                                    :permissionDestroy="'countries.delete'"
                                    @edit="edit"
                                    @delete="destroy"
                                ></crm-settings>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>

            <div class="app-modal app-modal__full modal-color-bg">
                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerCreate"
                    ref="drawerCreate"
                    size="60%"
                    @opened="drawerOpened('drawerCreateChild')"
                    @closed="drawerClosed('drawerCreateChild')"
                >
                    <div>
                        <crm-create
                            ref="drawerCreateChild"
                            drawer="drawerCreate"
                        >
                        </crm-create>
                    </div>
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerUpdate"
                    size="60%"
                    ref="drawerUpdate"
                    @opened="drawerOpened('drawerUpdateChild')"
                    @closed="drawerClosed('drawerUpdateChild')"
                >
                    <crm-update
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    ></crm-update>
                </el-drawer>
            </div>
        </div>
		   <el-dialog :title="$t('message.users')" :visible.sync="dialogTableVisible" @closed="closedDialog" top="5vh">
				<el-table :data="users" height="500"  style="width: 100%" v-loading="loadingData">
					<el-table-column property="id" label="#" width="70"></el-table-column>
					<el-table-column :label="$t('message.users')" width="350">
						<template slot-scope="item">
							<!-- {{item.row.user ? item.row.user.surname : ''}} -->
							{{item.row.user ? item.row.user.name : ''}}
						</template>
					</el-table-column>
					<el-table-column :label="$t('message.review')">
						<template slot-scope="item">
							{{ item.row.review ? $t('message.reviewed') : $t('message.not_reviewed') }}
						</template>
					</el-table-column>
					<el-table-column property="reviewed_date" :label="$t('message.reviewed_date')">
					</el-table-column>
				</el-table>
			</el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions, mapState } from "vuex";
import SelectNotificationTypes from '../../components/inventory/select-notification-types';
import SelectUsers from '../../components/inventory/select-users';

export default {
    name: "countries",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        CrmUpdate,
        SelectNotificationTypes,
        SelectUsers,
    },

    data() {
        return {
			dialogTableVisible: false,
			users: [],
			loadingData: false
		  };
    },

    computed: {
        ...mapGetters({
            list: "systeamNotification/list",
            columns: "systeamNotification/columns",
            pagination: "systeamNotification/pagination",
            filter: "systeamNotification/filter",
            sort: "systeamNotification/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "systeamNotification/index",
            setPagination: "systeamNotification/setPagination",
            updateSort: "systeamNotification/updateSort",
            updateFilter: "systeamNotification/updateFilter",
            updateColumn: "systeamNotification/updateColumn",
            updatePagination: "systeamNotification/updatePagination",
            show: "systeamNotification/show",
            empty: "systeamNotification/empty",
            delete: "systeamNotification/destroy",
            refreshData: "systeamNotification/refreshData",
            getNotificationUsers: "systeamNotification/getNotificationUsers",
        }),
		  showItems(notification_id){
				if (notification_id) {
					this.dialogTableVisible = true;
					this.loadingData = true;
					this.getNotificationUsers(notification_id).then((res) => {
						this.loadingData = false;
						if (res.users) {
							this.users = res.users;
						}
					}).catch((err) => {
						this.loadingData = false;
						this.$alert(err);
					});
				}
			},
			closedDialog(){
				this.users = [];
			}
    },
	 
    destroy(model) {          
          this.delete(model.id)
              .then(res => {
                  this.updateCountries();
                  this.$alert(res);
                  this.fetchData()
              })
              .catch(err => {
                this.$alert(err);
              })
      },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('systeamNotification/EMPTY_LIST');
        next()
    },
};
</script>

